.eve_card {
    background: #fff;
    /* min-height: 436px; */
    border-radius: 4px;
    position: relative;
    width:100%;
    padding-right: 10px;
    padding-left: 10px;

}
.eve_card h1{
    color:#7266FB;
    border-bottom:#D3D8DA 1px solid;
    padding:20px;
    text-align:center;
    font-size:20px;
    font-weight:500;
    margin-bottom:0;
    text-transform:uppercase;
}

.eve_card .ev_cont {
    padding: 15px;
}

.eve_card .ev_cont p {
    font-size: 24px;
    line-height: 44px;
    font-weight: 400;
}

.up_top_text{
    font-size:16px;
    font-family: 'open-sansregular';
    color:#818E94;
}


.up_top_text h2{
    font-weight: 600;
}

.ellipse {
    margin: 0;
    padding: 0;
    text-align: left;
    padding-top: 5px;
    float: left;
}
.ellipse li{
    display:inline-block;
}

.ellipse li img{
    width:56px;
    height:56px;
    border-radius:100px;
    border:#fff 2px solid;
    position:relative;
     transition: 0.3s;
}

.ellipse li img:hover{
    border:#05CE78 2px solid;
}

.ellipse .even {
    position: relative;
    z-index: 0;
}

.ellipse .odd {
    position: relative;
    left: -16px;
    width: 26px;
}

.ev_cont h2 {
    font-size: 20px;
    color: #222831;
    line-height: 30px;
    font-family: 'open-sansbold';
    letter-spacing: 0;
    margin-top: 8px;
    margin-bottom: 0;
}

.event_date {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.10);
    padding: 10px;
    text-align: center;
    color: #222831;
    font-size: 34px;
    font-family: 'Roboto-Bold';
    line-height: 24px;
    width: 70px;
    height: 70px;
}

.event_date span{
    font-size:20px;
    font-family: 'Roboto-Regular';
    display:block;
    text-align:center;
    color:#818E94;
    margin-top: 7px;
    text-transform:uppercase
}

.up_col{
    padding-top:7px;
    display: flex;
    justify-content: space-between;
}

.up_col h2 {
    font-size: 20px;
    color: #4D4D4D;
    max-width: 239px;

}
.saprator{
    height: 1px;
    background: #E8E9E9;
    width: 104%;
    margin-left: -2%;
    margin-bottom: 15px;
    margin-top: 15px;
}

.evnt_link_sec {
    padding-top: 20px;
}

.view_al{
    text-decoration: none;
    color:#7266FB;
    font-size:20px;
    font-weight:400;
    text-transform:uppercase;
     background: #EEEEEE;
    padding: 12px 20px;
    border-radius: 0 0 4px 4px;
    text-align: right;

    position: absolute;
    width: 89.4%;
    bottom: 0;
    left: 0;
}

.view_al:focus{
    color:#fff;
     background: #7266FB;
}

.view_al svg{
    font-size:24px;
    margin-left: 6px;
    margin-bottom: -5px;
}


.lower{
    width:100%;
    height:20%;
   margin-top: 20px;
   display: flex;
}

/* .btns{
    width: 100%;
    height: 20%;
    margin-left: 18px;
    background-color: black;
} */


.evnt_link_sec{
   display: inline-block;
   width: 100%;

}

.btnAbout{
    width:100%;
    margin-bottom: 4px;
}

.btnOthers{
    width:101%;
    display: flex;
    margin-top: 5px;
}
.evnt_link_sec .about {
    justify-content: space-between;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.evnt_link_sec p{
    text-align: left;
    padding: 0px;
    font-size: 20px;
    font-family: 'Roboto-Medium';
    margin: 0;
}

.evnt_link_sec a {
    width:52%;
    text-decoration: none;
    background: #EEEEEE;
    border-radius: 4px;
    text-align: center;
    color: #7266FB;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin-bottom: 4px;
    text-transform: uppercase;
     margin-right: 5px;
}

.evnt_link_sec a:hover{
    text-decoration:none
}
.evnt_link_sec a:focus{
	background: #7266FB;
	color: #fff;
}

.evnt_link_sec a svg {
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: 32px;
    margin-top: -5px;

}

.evnt_link_sec {
    padding-top: 0px;
}

.up_event_border {
    height: 1.2px;
    background: #EEEEEE;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
}

.ev_cont div:last-child .up_event_border{
    display: none;
}