@font-face {
  font-family: "Roboto-Light";
  src: url("../../assets/Fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../assets/Fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../../assets/Fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../assets/Fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("../../assets/Fonts/Helvetica.ttf");
}

@font-face {
  font-family: "open-sansregular";
  src: url("../../assets/Fonts/opensans/opensans-regular-webfont.woff2")
      format("woff2"),
    url("../../assets/Fonts/opensans/opensans-regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open-sanssemibold";
  src: url("../../assets/Fonts/opensans/opensans-semibold-webfont.woff2")
      format("woff2"),
    url("../../assets/Fonts/opensans/opensans-semibold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open-sansbold";
  src: url("../../assets/Fonts/opensans/opensans-bold-webfont.woff2")
      format("woff2"),
    url("../../assets/Fonts/opensans/opensans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.onboardingbg {
  background: url(../../assets/rawimg/blue_bg.png) no-repeat;
  background-position: center;
  background-size: 110%;
  height: 101.8vh;
  position: relative;
  box-shadow: inset 0 0 0 2000px rgba(38, 46, 110, 0.55);
}
.on_boarding_main {
  padding-left: 195.86px;
  padding-top: 160.86px;
  padding-right: 1.5%;
  max-width: 1920px;
  height: 336px;
  margin: 0 auto;
}
.on_boarding_welcome {
  padding-left: 195.86px;
  padding-top: 160.86px;
  padding-right: 1.5%;
  height: 336px;
  margin: 0 auto;
  background-color: rgba(38, 46, 110, 0.6);
}
.login_message h1 {
  width: 518px;
  color: #ffffff;
  font-size: 65px;
  font-family: "open-sansbold";
  padding: 6px 0px;
}
.link {
  font-size: 48px;
  font-family: "Roboto-Bold";
  color: #ffffff;
  height: 48px;
}
.headingTextFirst {
  font-size: 48px;
  color: #ffffff;
  font-family: "Roboto-Light";
  opacity: 0.7;
  margin-bottom: 16px;
  height: 48px;
}
.headingText {
  font-size: 38px;
  color: #ffffff;
  font-family: "Roboto-Light";
  opacity: 0.7;
  height: 38px;
}
.login_message div:nth-child(2) {
  margin-top: 50.37px;
}
.on_boarding_otp {
  margin-top: 126px;
}
.on_boarding_otp .opt_number {
  padding: 6px 0px;
  font-size: 250px;
  font-family: "Roboto-Medium";
  letter-spacing: 50px;
  color: #ffffff;
}
.on_boarding_otp .opt_number_expired {
  padding: 6px 0px;
  font-size: 250px;
  font-family: "Roboto-Medium";
  letter-spacing: 50px;
  color: rgba(38, 46, 110, 0.5);
}
.on_boarding_otp div:nth-child(2) {
  display: flex;
  flex-direction: row;
}
.on_boarding_otp div:nth-child(2) p:first-child {
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  opacity: 0.6;
  font-family: "Roboto-Regular";
  font-size: 22px;
  margin-right: 14px;
}
.on_boarding_otp div:nth-child(2) p:nth-child(2) {
  letter-spacing: 0.88px;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  opacity: 1;
  font-family: "Roboto-Bold";
  font-size: 22px;
}
.get_new_code_btn {
  margin-top: 31px;
  width: 446px;
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.get_new_code_btn span {
  letter-spacing: 0.88px;
  color: rgba(114, 102, 251, 1);
  text-transform: uppercase;
  opacity: 1;
  font-family: "Roboto-Bold";
  font-size: 22px;
}
.MuiLinearProgress-colorPrimary {
  background-color: #ffffff;
  opacity: 0.25;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #ffffff !important;
  opacity: 1 !important;
}
.welcome_message h1 {
  width: 910px;
  height: 71px;
  color: #ffffff;
  font-size: 58px;
  font-family: "Roboto-Bold";
}
.welcome_message p {
  font-size: 24px;
  font-family: "Roboto-Light";
  width: 910px;
  height: 29px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.welcome_child_boxes {
   padding-top: 160px;
   padding-left: 195.86px;
   display: flex;
   flex-direction: row;
   align-items: center;
}
.welcome_child_boxes > div {
  width: 490px;
  text-align: center;
  height: 270px;
  border-right: 1px solid #fff;
}
.welcome_child_boxes > div:nth-child(3){
  border-right: transparent;

}
.welcome_child_boxes .box_number {
    width: 80px;
    height: 80px;
    background-color: rgba(114, 102, 251, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 30px;
}
.welcome_child_boxes .box_number span {
   color: rgba(255, 255, 255, 1);
    font-size: 40px;
    font-family: "Roboto-Bold";
}
.welcome_child_boxes .box_heading {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-family: "Roboto-Bold";
  margin-bottom: 30px;
}
.welcome_child_boxes .box_para {
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  font-size: 20px;
  font-family: "Roboto-Regular";
}
.support-bg {
  background-color: #000000;
  height: 100vh;
}

.support {
  margin-top: 438px;
}
.support p {
  font-family: "Roboto-Light" !important;
  color: #FFFFFF;
  font-size: 38px;
  letter-spacing: 1px;
  opacity: 0.7;
}
.support span {
  font-weight: 700;
  opacity: 1;
}