.input-field{
    position: relative;
}

.cancel {
    align-self: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 11px;
}

.cancel  svg{
    font-size: 30px;
    opacity: 0.4;
}


.char {
    text-align: right;
    font-size: 16px;
    color: #D3D8DA;
    font-family: 'Roboto-Regular';
    position: absolute;
    right: 0;
}
.note {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-size: 22px;
    margin-top: 30px;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: 'Roboto-Medium';
    color: #818E94 !important;
}

.categoriesCont {
    margin: 0px auto;
    width: fit-content;
}

.phoneErrorshow .form-control+div::before{
    color: red !important;
}

.phoneErrorshow .form-control:focus {
    border: 2px solid red !important ;
    box-shadow: none !important;
}

.phoneErrorshow .form-control {
    border: 1px solid red !important ;
    box-shadow: none !important;
    
}

.MuiButton-outlined {
    border: 1px solid #D3D8DA !important;
    padding: 5px 15px;
}

.donation-card input::placeholder {
    color: #6c757d;
    opacity: 0.4;
    font-size: 24px !important;
    font-weight: 400;
    position: relative;
    top: -5px;
}

.messsage_field{
    margin-top: 30px;
}

.messsage_field .MuiOutlinedInput-input {
    padding: 0 22px;
    height: 70px;
    font-family: 'Roboto-Regular';
    font-size: 24px !important;
}

.messsage_field .MuiOutlinedInput-input::placeholder {
    position: relative;
    top: 0px;
}

.cancel {
    align-self: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 19px;
}

.categoriesCont{
    margin-bottom: 24px;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #D3D8DA !important;
}

.react-tel-input .form-control {
    border: 1px solid #D3D8DA;

}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #7266FB !important;
}

.MuiFormLabel-root.Mui-focused{
    color: #7266FB !important;
}

.react-tel-input .form-control:focus{
    border-color: #7266FB !important;
}

.react-tel-input .form-control:focus+div:before {
    color: #7266FB !important;
}




.donation-contianer .MuiTextField-root .MuiInputLabel-formControl {
    top: 3px;
    left: 5px;
    position: absolute;
    font-size: 24px;
    font-weight: 300;
}

.donation-contianer .MuiOutlinedInput-input {
    padding: 0 20px;
    height: 70px;
    font-size: 24px;
    font-weight: 400 !important;
    font-family: Roboto-Regular !important;
    color: #495057;
}

.react-tel-input .form-control+div:before {
    top: -11px !important;
    font-size: 16px !important;
    color: #818E94;
    font-family: "Roboto-Regular";
    left: 13px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -10px) scale(0.7) !important;
    background: #fff;
    padding-right: 11px;
    color: #818E94;
}

.donation-contianer .MuiFormLabel-root {
    font-weight: 400 !important;
    font-family: Roboto-Regular !important;

}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}



 .MuiFormLabel-root.Mui-error{
    color : rgba(0, 0, 0, 0.6) !important
}

.floatingLabel{
    color : red !important
}
.EmailfloatingLabel {
     color : red !important;
}
.note-div .MuiOutlinedInput-input {
        padding: 0px 44px 0px 14px;
}
