.footer-background {
    background-color: #2A3296;
    position: absolute;
    width: 100vw;
    bottom: 0;
    opacity: .8;
    height: 55px;
}

div.footer{
    width: 100%;
    height: 55px;
    display: flex;
    padding: 10px 30px 0px 30px;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
}

.footer-text {
    color: #9594ca;
    font-size: 14px;
    font-weight: lighter;
    padding-top: 4px;
}

.footer img {
    width: 83px;
    height: 32px;
    margin-top: 5px;
}

.footer_btm_logo {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Roboto-Regular';
    padding-top: 10px;
    opacity: 0.6;
}

.footer_btm_logo span{
    position:relative;
    top: 2px;
}