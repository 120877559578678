/* ===== Categories Tab Css Start==== */

.DonationCategories {
    overflow-y: auto;
    max-height: 530px;
}

.DonationCategories .MuiTabs-flexContainer {
    display: block;
    white-space: initial;
}

.DonationCategories .MuiButtonBase-root {
    width: 100%;
    display: block;
    min-width: initial !important;
    max-width: initial;
    text-align: left !important;
    box-shadow: 0px 1px 4px #00000017;
    border: 1px solid #D3D8DA;
    border-radius: 4px;
    margin-bottom: 10px;
    height: 108px;

}

.DonationCategories .MuiButtonBase-root.Mui-selected {
    background: #05CE78;
}

.DonationCategories .MuiTab-textColorPrimary {
    color: #222831 !important;
    font-size: 34px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
    font-weight: 600;
}

.DonationCategories .MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
    font-size: 34px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
}

.DonationCategories .MuiButtonBase-root .DonationCategories .MuiTabs-root {
    display: block;
    overflow: visible;
    min-height: auto;
}

.DonationCategories .MuiTab-wrapper {
    width: 100%;
    display: block;
    align-items: initial;
    flex-direction: column;
    justify-content: inherit;
}

.DonationCategories .catTabIcon {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.DonationCategories .catTabIcon .icon-active {
    display: none;
}

.DonationCategories .MuiTabs-indicator {
    display: none;
}

.DonationCategories .catTabIcon .icon-inactive {
    color: #D3D8DA !important;
    font-size: 28px;
}

.DonationCategories .Mui-selected .catTabIcon .icon-inactive {
    display: none;
}

.DonationCategories .Mui-selected .catTabIcon .icon-active {
    display: block;
    font-size: 28px;
}

.DonationCategories .catTabIcon label {
    display: inline-block !important;
    margin-bottom: 0 !important;
    margin-left: 20px;
    line-height: 42px;
    cursor: pointer;
}

.ProceedBtn {
    margin-top: 40px;
}

.ProceedBtn .MuiButtonBase-root {
    color: #fff;
    background-color: #05CE78;
    font-size: 18px;
    text-transform: uppercase;
    border: 4px;
    width: 100%;
    height: 60px;
    box-shadow: none;
}

.ProceedBtn .MuiButtonBase-root:hover {
    color: #fff;
    background-color: #05CE78;
    box-shadow: none;
}

.ProceedBtn .MuiButtonBase-root svg {
    font-size: 20px;
    position: relative;
    top: -2px;
}

/* ===== Categories Tab Css End==== */

/* ===== Modal Css Start ==== */

.ModalBody {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ModalBody .ModalBodyInner {
    background: #fff;
    border-radius: 4px;
    padding: 40px;
    width: 1220px;
    margin: 0 25px;

}

.ModalBody .ModalBodyInner .transition-modal-title {
    color: #222831;
    font-size: 48px;
    margin-bottom: 20px;
}

/* ===== Modal Css End==== */


/* ===== DonationPackage Css Start==== */

.DonationPackage {}

.DonationPackage .MuiTabs-flexContainer {
    display: flex;
    white-space: initial;
    margin: 0 -5px;
}

.DonationPackage .MuiButtonBase-root {
    min-width: initial !important;
    max-width: initial;
    text-align: left !important;
    box-shadow: 0px 1px 4px #00000017;
    border: 1px solid #D3D8DA;
    border-radius: 4px;
    margin-bottom: 10px;
    height: 100px;
    flex: 1;
    margin: 0 5px;

}

.DonationPackage .MuiButtonBase-root.Mui-selected {
    background: #05CE78;
}

.DonationPackage .MuiTab-textColorPrimary {
    color: #222831 !important;
    font-size: 34px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
    font-weight: 600;
}

.DonationPackage .MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
    font-size: 34px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
}

.DonationPackage .MuiButtonBase-root .DonationPackage .MuiTabs-root {
    display: block;
    overflow: visible;
    min-height: auto;
}

.DonationPackage .MuiTab-wrapper {
    width: 100%;
    display: block;
    align-items: initial;
    flex-direction: column;
    justify-content: inherit;
}

.DonationPackage .catTabIcon {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.DonationPackage .catTabIcon .icon-active {
    display: none;
}

.DonationPackage .MuiTabs-indicator {
    display: none;
}

.DonationPackage .catTabIcon .icon-inactive {
    color: #D3D8DA !important;
    font-size: 28px;
}

.DonationPackage .Mui-selected .catTabIcon .icon-inactive {
    display: none;
}

.DonationPackage .Mui-selected .catTabIcon .icon-active {
    display: block;
    font-size: 28px;
}

.DonationPackage .catTabIcon label {
    display: inline-block !important;
    margin-bottom: 0 !important;
    margin-left: 20px;
    line-height: 42px;
    cursor: pointer;
}


/* ===== DonationPackageTime Css Start==== */

.DonationPackageTime {
    margin-top: 20px;
}

.DonationPackageTime .MuiTabs-flexContainer {
    display: flex;
    white-space: initial;
}

.DonationPackageTime .MuiButtonBase-root {
    min-width: initial !important;
    max-width: initial;
    text-align: left !important;
    box-shadow: 0px 1px 4px #00000017;
    border: 1px solid #D3D8DA;
    border-radius: 0px;
    margin-bottom: 10px;
    height: 70px;
    flex: 1;
}

.DonationPackageTime .MuiButtonBase-root:first-child {
    border-radius: 4px 0 0 4px;
    border-right: none;
}

.DonationPackageTime .MuiButtonBase-root:last-child {
    border-radius: 0px 4px 4px 0px;
    border-left: none;
}


.DonationPackageTime .MuiButtonBase-root.Mui-selected {
    background: #05CE78;
    border: 1px solid #05CE78;
}

.DonationPackageTime .MuiTab-textColorPrimary {
    color: #222831 !important;
    font-size: 24px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
    font-weight: 600;
}

.DonationPackageTime .MuiTab-textColorPrimary.Mui-selected {
    color: #ffffff !important;
    font-size: 24px !important;
    letter-spacing: 0.24px !important;
    opacity: 1;
    text-transform: capitalize !important;
}

.DonationPackageTime .MuiButtonBase-root .DonationPackageTime .MuiTabs-root {
    display: block;
    overflow: visible;
    min-height: auto;
}

.DonationPackageTime .MuiTab-wrapper {
    width: 100%;
    display: block;
    align-items: initial;
    flex-direction: column;
    justify-content: inherit;
}

.DonationPackageTime .catTabIcon {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.DonationPackageTime .catTabIcon .icon-active {
    display: none;
}

.DonationPackageTime .MuiTabs-indicator {
    display: none;
}

.DonationPackageTime .catTabIcon .icon-inactive {
    color: #D3D8DA !important;
    font-size: 28px;
}

.DonationPackageTime .Mui-selected .catTabIcon .icon-inactive {
    display: none;
}

.DonationPackageTime .Mui-selected .catTabIcon .icon-active {
    display: block;
    font-size: 28px;
}

.DonationPackageTime .catTabIcon label {
    display: inline-block !important;
    margin-bottom: 0 !important;
    margin-left: 20px;
    line-height: 42px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

/* ===== DonationPackageTime Css End==== */

/* ===== DonationPackage Css End==== */