.book_name {
    color: #4D4D4D;
    font-size: 20px;
    text-align: center;
    position: absolute;
    bottom: 40px;
    font-family: 'open-sansbold';
    width: 100%;
    left: 0;
}
#slider {
    opacity:1;
    transition: opacity 1s;
}

#slider.fadeOut {
    opacity:0;
}