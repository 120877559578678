.slat_time_card {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
.slat_time_card .slat_name{
    color:#222831;
    font-size:24px;
    font-family: 'Roboto-Bold';
    font-weight: normal;
}

.slat_time_card .slat_time{
    color:#222831;
    font-size:24px;
    font-family: 'Roboto-Bold';
    font-weight: normal;
}

.center{
    text-align:center;
}

.large_time {
    padding: 15px;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.18);
    margin-top: 15px;
    border-radius: 4px;
    text-align: center;
    height: 141px;
    padding-top: 20px;
}
.large_time .time_col .unit {
    font-family: 'Roboto-Light';
    font-size: 96px;
    color: #7266FB;
    line-height: 70px;
    margin-bottom: 2px;
}

.time_col {
    text-align: center;
    display: inline-block;
    vertical-align: top;
}


.time_col span {
    font-size: 90px;
    font-weight: 500;
    line-height: 60px;
    border-radius: 100px;
    font-family: monospace;
    color: #818E94;
}

.time_col h2 {
    font-size: 24px !important;
    text-transform: uppercase;
    color: #818E94 !important;
    font-family: 'Roboto-Regular' !important;
    margin: 0;
    font-weight: normal !important;
    margin-top: 12px !important;
}
.tm_hnt {
    font-size: 20px;
    color: #818E94;
    text-align: center;
    font-family: 'Roboto-Regular';

}

.slat_time_card_bottom {
    padding: 9px 15px;
    border-top: #D3D8DA 1px solid;
    display: flex;
    justify-content: space-between;
}
.salah-childs .slat_time_card_bottom:first-child {
    border-top: none !important;
}