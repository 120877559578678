.donate_more_btn button{
    width: 100% !important;
}

.success_border_line {
    background: #eaeaea;
    height: 1px;
    margin: -50px;
    margin-right: -50px;
    margin-bottom: 30px;
    margin-top: 40px;
}


.Donation-Successful {
    color: #222831;
    font-size: 34px !important;
    font-family: 'Roboto-Bold' !important;
    margin-top: 30px !important;
}
.jazak_Allah{
    text-align: center;
    margin-top: 40px;
}

.don_number{
    text-align: center;
    margin-top: 40px;
}
.btn_donate button {
    margin-bottom: 5px;
    height: 60px;
    width: 290px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: 'Roboto-Medium';
    border-radius: 4px !important;
    position: relative;
    display: flex;
    align-items: center;
}

.btn_donate button i {
    position: absolute;
    left: 20px;
    font-size: 18px;
}

.btn_donate button span{
    font-family: 'Roboto-Medium' !important;
    letter-spacing: 0.45px;
}


.succcess_page {
    background: #fff;
    width: 600px;
    margin: 0 auto;
    box-shadow: 10px 34px 89px 32px #fff;
    padding: 30px;
    margin-top: 3vw;
}
.success_text{
    color: #818E94;
    font-size: 22px;
    font-family: 'open-sansregular';
    line-height: 40px;
}

.success_text p{
    color: #818E94;
    font-size: 22px;
    font-family: 'open-sansregular';
    line-height: 40px;
    margin: 0;
}


.don_number_ref{
    color: var(--text-color) !important;  
}

span

.don_number span{
    color: #747474 !important;
}

@media (max-width:960px){
    .success_border_line {
    background: #eaeaea;
    height: 1px;
    margin: -25px;
    margin-right: -25px;
    margin-bottom: 30px;
    margin-top: 40px;

    }

    .succcess_page{
        width: 100%;
    }

}