@font-face {
    font-family: 'Roboto-Light';
    src: url('./assets/Fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('./assets/Fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./assets/Fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('./assets/Fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/Fonts/Helvetica.ttf');
}

@font-face {
    font-family: 'open-sansregular';
    src: url('./assets/Fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
         url('./assets/Fonts/opensans/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'open-sanssemibold';
    src: url('./assets/Fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'),
         url('./assets/Fonts/opensans/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'open-sansbold';
    src: url('./assets/Fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
         url('./assets/Fonts/opensans/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }

.App {
    width: 100%;
    height: 100vh;
    background-image: url('./assets/img/Khushu-Pattern-Design_3.png');
}

body {
    background-image: url(assets/rawimg/background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.donation-container {
    display: flex;
    background-image: url(assets/img/Khushu-Pattern-Design_3.png);
    padding: 30px;
    height: 100vw;
}

.apiLoader {
    width: 120px !important;
    height: 120px !important;
    position: absolute;
    top: 50%;
    right:50%;
    color: #2A3296 !important;

}

*:focus{
    outline : none !important;
}
.Mui-error.MuiFormLabel-filled span {
    color: red !important;
}

.SECURED_BY_AWS{
    color: #A9A9A9;
    font-size: 14px;
    text-align: center;
    font-family: 'Roboto-Regular';
    text-transform: uppercase;
}

.SECURED_BY_AWS span label{
    font-family: 'Roboto-Medium';
}


.SECURED_BY_AWS svg{ 
    font-size: 20px;
    position: relative;
    top: -1px;
}

.MuiButton-root{
    display: flex;
    align-items: center;
}

.btn_back_innrepage{
    margin-top: 41px;
}

.btn_back_innrepage button{
    margin-left:0px;
}

.btn_back_innrepage a{
    margin-bottom: 20px;
    display: block
}

.btn_back_innrepage button svg {
    position: absolute;
    left: 20px;
    top: 23px;
}

.page_link a {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Roboto-Medium';
    letter-spacing: 0.2px;
}

.page_link svg {
    position: relative !important;
    vertical-align: top;
    display: inline-block !important;
    top: 3px !important;
    left: 0 !important;
}

.page_link span {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin: 5px 15px;
}

.page_active{
    color: #BFCED7 !important;
}

.scrollbardark{
    overflow-y: scroll !important;
    height: 700px;
    padding-right: 20px;
}

div .scrollbardark::-webkit-scrollbar {
    width: 3px;
    height: 18px;
   
}
 
div .scrollbardark::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 3px #7266FB !important;
    box-shadow: inset 0 0 3px #7266FB !important;
    background-color: #7266FB !important;
    border-radius: 0px;
    height:50px !important;
    max-height: 20px;
    background-clip: content-box;
}
div .scrollbardark::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px #594fce ; 
    box-shadow: inset 0 0 3px #594fce ;
    background-color: #594fce;
    border-radius: 0px;
    margin: 0 30px !important;
    
}


